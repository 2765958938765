<template>
  <div style="margin: 0px !important; background: none; position: absolute;" class="h-16">
    <nav
      id="nav-bar-background"
      ref="navBarBG"
      class="h-16 fixed top-0 w-full z-20"
    >
      <div
        ref="navBar"
        id="nav-bar"
        class="mobile-navbar-expanded sm:px-12 container mx-auto h-16"
      >
        <div class="relative flex items-center justify-between h-16">
          <div class="absolute inset-y-0 left-0 flex items-center lg:hidden cross">
            <!-- Mobile menu button-->
            <button
              aria-controls="mobile-menu"
              aria-expanded="false"
              v-on:click="mobileBtnClick"
              ref="mobileBtn"
              class="
                inline-flex
                items-center
                justify-center
                rounded-md
                text-black
                focus:outline-none
                focus:ring-2 focus:ring-inset focus:ring-white
                menu-btn
              "
              type="button"
            >
              <close-home class="close-icon" style="width: 50px; max-height: 35px;" />
              <hamburger-icon :isWhite="true" class="menu-icon" style="width: 70px; max-height: 65px; color: white;" />
            </button>
          </div>
          <div
            class="
              flex-1 flex
              items-center
              justify-center
              lg:items-stretch
              lg:justify-between
              box-nav
            "
          >
            <div class="flex-shrink-0 flex items-center first-section">
              <router-link to="/">
                <napps-help />
              </router-link>
            </div>
            <div class="hidden lg:block lg:ml-6">
              <div style="height: 100%;" class="flex space-x-4">
                <router-link
                  to="/features"
                  class="
                    text-black
                    px-3
                    py-2.5
                    rounded-md
                    text-lg
                    font-bold
                    nav-item
                  "
                >
                  {{ $t("navbar.features") }}</router-link
                >
                <router-link
                  to="/pricing"
                  class="
                    text-black
                    px-3
                    py-2.5
                    rounded-md
                    text-lg
                    font-bold
                    nav-item
                  "
                  >{{ $t("navbar.pricing") }}</router-link
                >
                <router-link
                  to="/aboutus"
                  class="
                    text-black
                    px-3
                    py-2.5
                    rounded-md
                    text-lg
                    font-bold
                    nav-item
                  "
                  >{{ $t("navbar.about") }}</router-link
                >
                <router-link
                  to="/support"
                  class="
                    text-black
                    px-3
                    py-2.5
                    rounded-md
                    text-lg
                    font-bold
                    nav-item
                  "
                  >{{ $t("support.title") }}</router-link
                >
              </div>
            </div>
            <div class="hidden lg:block lg:ml-6 third-section">
              <!-- Not logged in -->
              <div v-if="!isLoggedIn" class="flex space-x-4 items-center">
                <router-link
                  to="/login"
                  class="button-nav-top button-outline"
                  >{{ $t("navbar.login") }}</router-link
                >
                <router-link
                  to="/startnow?redirectTo=sync"
                  id="nav-bar-btn"
                  class="button-nav-top button-fill"
                  >{{ $t("navbar.startnow") }}</router-link
                >
              </div>
              <!-- Logged in and shop configured -->
              <div
                v-else-if="getShop.type != 'Unknown'"
                class="flex space-x-4 items-center"
              >
                <a
                  href="#"
                  v-on:click="onDashboardClick"
                  class="text-black px-3 py-2 rounded-md text-lg font-bold fill-white"
                >
                  {{ $t("navbar.dashboard") }}
                </a>
                <a
                  v-on:click="onLogout"
                  id="nav-bar-btn"
                  :class="{ 'white-outline' : !fillBG}"
                  class="button-nav cursor-pointer text-lg white-outline"
                  >{{ $t("navbar.logout") }}
                </a>
              </div>
              <!-- Logged in and shop NOT configured -->
              <div
                v-else-if="getShop.type == 'Unknown'"
                class="flex space-x-4 items-center"
              >
                <router-link
                  to="/shop/choose"
                   :class="{ 'fill-white': !fillBG }"
                  class="text-black px-3 py-2 rounded-md text-lg font-bold fill-white"
                >
                  {{ $t("navbar.configure") }}
                </router-link>
                <a
                  v-on:click="onLogout"
                  id="nav-bar-btn"
                   :class="{ 'white-outline' :!fillBG}"
                  class="button-nav cursor-pointer text-lg white-outline"
                  >{{ $t("navbar.logout") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile menu, show/hide based on menu state. -->
      <div
        ref="mobileMenu"
        class="hidden lg:hidden mobile-navbar"
        id="mobile-menu"
      >
        <div style="padding-left: 30px;" class="px-2 pt-2 pb-3 space-y-1 text-left">
          <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300  hover:text-white" -->
          <router-link
            to="/features"
            class="
              first-option
              text-gray-600
              
              hover:text-white
              block
              px-3
              py-2
              rounded-md
              text-base
              font-medium
            "
            aria-current="page"
            >{{ $t("navbar.features") }}</router-link
          >
          <router-link
            to="/pricing"
            class="
              second-option
              text-gray-600
              
              hover:text-white
              block
              px-3
              py-2
              rounded-md
              text-base
              font-medium
            "
            >{{ $t("navbar.pricing") }}</router-link
          >
          <router-link
            to="/aboutus"
            class="
              third-option
              text-gray-600
              
              hover:text-white
              block
              px-3
              py-2
              rounded-md
              text-base
              font-medium
            "
            >{{ $t("navbar.about") }}</router-link>

          <router-link
            to="/support"
            class="
              third-option
              text-gray-600
              
              hover:text-white
              block
              px-3
              py-2
              rounded-md
              text-base
              font-medium
            "
            >{{ $t("support.title") }}</router-link>

        </div>
          <div class="auth-btns-container">
            <div v-if="!isLoggedIn" class="flex space-x-4 items-center mobile-buttons">
              <router-link
                to="/login"
                class="text-black px-3 py-2 rounded-md text-lg font-bold"
                >{{ $t("navbar.login") }}</router-link
              >
              <router-link
                to="/startnow?redirectTo=sync"
                id="nav-bar-btn"
                class="button-nav cursor-pointer text-lg"
                >{{ $t("navbar.startnow") }}</router-link
              >
            </div>
            <!-- Logged in and shop configured -->
            <div
              v-else-if="getShop.type != 'Unknown'"
              class="flex space-x-4 items-center mobile-buttons"
            >
              <a
                href="#"
                v-on:click="onDashboardClick"
                class="text-black px-3 py-2 rounded-md text-lg font-bold"
              >
                {{ $t("navbar.dashboard") }}
              </a>
              <a
                v-on:click="onLogout"
                id="nav-bar-btn"
                class="button-nav cursor-pointer text-lg"
                >{{ $t("navbar.logout") }}
              </a>
            </div>
            <!-- Logged in and shop NOT configured -->
            <div
              v-else-if="getShop.type == 'Unknown'"
              class="flex space-x-4 items-center mobile-buttons"
            >
              <router-link
                to="/shop/choose"
                class="text-black px-3 py-2 rounded-md text-lg font-bold"
              >
                {{ $t("navbar.configure") }}
              </router-link>
              <a
                v-on:click="onLogout"
                id="nav-bar-btn"
                class="button-nav cursor-pointer text-lg"
                >{{ $t("navbar.logout") }}
              </a>
            </div>
          </div>
      </div>
      <div :class="{ 'nav-fill': fillBG }" class="nav-bg ">
      </div>
    </nav>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { gsap } from "gsap";
import { useStore } from "@/store";
import axios from "axios";
import NappsHelp from "../Icons/NappsHelp.vue";
import LogoWhite from "../Icons/LogoWhite.vue";
import HamburgerIcon from "../Icons/HamburgerIcon.vue";
import CloseHome from "../Icons/CloseHome.vue";

export default defineComponent({
  data: function () {
    return {
      isOpen: false,
      tlx: undefined,
    };
  },
  components: {
    NappsHelp,
    LogoWhite,
    HamburgerIcon,
    CloseHome,
  },
  props: {
    fillBG: Boolean,
  },
  computed: {
    isLoggedIn() {
      return useStore().getters["auth/isLoggedIn"];
    },
    getShop() {
      return useStore().getters["auth/shop"];
    },
    isMenuOpen() {
      return this.isOpen;
    },
  },
  onDismout() {
    window.onscroll = null;
  },
  mounted() {
    const path = this.$route.path;

    if (path === "/features" || path === "/" || path === "/pricing" || path === "/support") {
      this.$refs.navBarBG.classList.remove("nav-bar-shadow");
    } else {
      this.$refs.navBarBG.classList.add("nav-bar-shadow");
      this.$refs.navBarBG.classList.add("nav-bar-force-white-background");
    }

    let width = window.innerWidth;

    // Allow only on mobile
    if (width <= 640) {
      let prevScrollpos = window.pageYOffset;

      const self = this;
      window.onscroll = function () {
        const currentScrollPos = window.pageYOffset;

        if (prevScrollpos < 0) {
          prevScrollpos = currentScrollPos;
          return;
        }

        if (prevScrollpos > currentScrollPos) {
          self.$refs.navBar.style.top = "0";
          self.$refs.navBarBG.style.top = "0";
        } else if (!self.isOpen) {
          self.$refs.navBar.style.top = "-64px";
          self.$refs.navBarBG.style.top = "-64px";
        }

        prevScrollpos = currentScrollPos;
      };
    }
  },
  methods: {
    onDashboardClick: function (event) {
      useStore().dispatch("auth/openDashboard", {
        href: "https://" + this.getShop.id + ".napps-solutions.com",
      });
    },
    onLogout: function (event) {
      useStore().dispatch("auth/logout");
    },
    mobileBtnClick: function (event) {
      if (this.isOpen) {
        // this.$refs.mobileMenu.style.display = "none";

        
        this.isOpen = false;
        this.$refs.mobileBtn.classList.remove("is-active");

        if (this.tlx) {
          this.tlx.kill();
        }

        let tl = gsap.timeline();
        tl.to(".auth-btns-container", { opacity: "0", duration: 0.2 });
        tl.to(".fourth-option", { opacity: "0", duration: 0.1 });
        tl.to(".third-option", { opacity: "0", duration: 0.1 });
        tl.to(".second-option", { opacity: "0", duration: 0.1 });
        tl.to(".first-option", { opacity: "0", duration: 0.1 });
        tl.to(".close-icon", { display: "none", duration: 0.1 });
        tl.to(".menu-icon", { display: "block", duration: 0.1 });
        tl.to(".mobile-navbar-expanded", {
          height: "64px",
          duration: 0.2,
          ease: "sine.in",
          onComplete: () => {
            this.$refs.mobileMenu.style.display = "none";
          },
        });
        tl.to(".logo-dark", { opacity: "1", duration: 0.2 });
        tl.to("#chat_layout", { display: "block", duration: 0.2 });
        this.tlx = tl;
      } else {
        this.isOpen = true;
        this.$refs.mobileBtn.classList.add("is-active");
        this.$refs.mobileMenu.style.display = "flex";

        if (this.tlx) {
          this.tlx.kill();
        }

        let tl = gsap.timeline();
        tl.to(".logo-dark", { opacity: "0", duration: 0.2 });
         tl.to(".menu-icon", { display: "none", duration: 0.1 });
        tl.to("#nav-bar", { backgroundColor: "#263238", duration: 0.3 });
        tl.to(".mobile-navbar-expanded", {
          height: "100vh",
          duration: 0.2,
          ease: "slow(0.7, 0.7, false)",
        });
        tl.to(".close-icon", { display: "block", duration: 0.1 });
        tl.to(".first-option", { opacity: "1", delay: 0.1, duration: 0.2 });
        tl.to(".second-option", { opacity: "1", duration: 0.2 });
        tl.to(".third-option", { opacity: "1", duration: 0.2 });
        tl.to(".fourth-option", { opacity: "1", duration: 0.2 });
        tl.to(".auth-btns-container", { opacity: "1", duration: 0.2 });
        tl.to("#chat_layout", { display: "none", duration: 0.2 });
        this.tlx = tl;
      }
    },
  },
});
</script>

<style scoped>

.auth-btns-container {
  opacity: 0;
}

.fourth-option {
  opacity: 0;
}

.third-option {
  opacity: 0;
}

.second-option {
  opacity: 0;
}

.first-option {
  opacity: 0;
}

.white-outline {
  border: 1px solid white !important;
  color: white !important;
}

.fill-white {
  background-color: white !important;
  color: #263238 !important;
}

.nav-bg {
  position: absolute;
  top: 0px;
  width: 100%;
  overflow: hidden;
  transition: padding-top 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  background-color: #263238;
}

.nav-fill {
  padding-top: 62px;
}

.button-nav-top {
  border-radius: 5px;
  color: white;
  font-family: GothamBook;
  padding: 7px 20px;
  font-size: 16px;
  line-height: 24px;
}

.button-nav-top:hover {
  opacity: .8;
}

.button-fill {
 background-color: white;
 border-width: 1px;
    border-style: solid;
    border-color: white;
    color: #101921;
}

.button-outline {
    border-width: 1px;
    border-style: solid;
    border-color: white;
    color: white;
}

.menu-icon > path {
  fill: white;
}

button:focus {
  outline: none !important;
  --tw-ring-inset: none !important;
}

.close-icon {
  fill: white;
  margin-right: 10px;
  display: none;
}

.close-icon:focus {
  outline: none !important;
}

.logo-white {
  display: none;
}
.first-section {
  width: 250px;
}

.third-section {
  width: 250px;
}

svg {
  max-width: 190px;
  max-height: 29px;
}

a {
  font-family: GothamBook;
  font-size: 16px;
  color: white;
}

.auth-btns-container {
  display: flex;
  flex-flow: row;
  background-color: white;
  padding-top: 30px;
  flex-shrink: 0;
  margin-top: auto;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.mobile-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row !important;
  width: 100%;
}

.mobile-buttons > a {
  flex: 1;
}

.auth-btns-container > a {
  margin: 6px;
}

.button-auth {
  max-height: 38px;
  border: 1px solid white;
  color: white;
  padding-bottom: 2px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 16px;
  font-family: GothamMedium;
  border-radius: 5px;
  vertical-align: middle;
  line-height: 38px;
}

.button-auth-register {
  color: #ffffff;
  background-color: #101921;
}

.menu-btn {
  transform: scale(0.6);
  margin-top: 5px;
}

.first-option,
.second-option,
.third-option,
.fourth-option,
.auth-btns-container {
  opacity: §;
}

.auth-btns-container {
  display: flex;
  flex-direction: row;
}

#nav-bar {

}

.nav-bar-shadow {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 30px 0px;
}

.nav-bar-force-white-background {
  background-color: white !important;

}

.mobile-navbar-expanded {
  width: 101%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  position: fixed;
  overflow: hidden;
  top: 0;
}

.blue-nav {
  background-color: rgb(43, 56, 78);

}

.mobile-navbar {
  position: fixed;
  top: 64px;
  background: transparent;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
  flex-direction: column !important;
}

.nav-item {
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  font-family: GothamMedium;
}

.button-nav {
  max-height: 38px;
  border: 1px solid #101921;
  color: #101921;
  padding-bottom: 2px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 16px;
  font-family: GothamBook;
  border-radius: 5px;
  vertical-align: middle;
  line-height: 38px;
}


@media only screen and (min-width: 1024px) {
  .logo-mobile {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .first-section {
    justify-content: center;
    z-index: 10000;
  }
  .logo-web {
    display: none;
  }
  .text-base {
    width: 100%;
    max-width: 320px;
    font-size: 30px;
    line-height: 38px;
    margin: 0px 0px 40px !important;
    color: white;
    font-family: "GothamBook";
  }
  .auth-btns-container > div {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .auth-btns-container > div > a:first-child {
    width: 100%;
    max-width: 200px;
    font-size: 20px;
    line-height: 0px;
    margin: 0px 0px 40px !important;
    padding: 25px;
    background-color: rgb(38, 50, 56);
    color: white;
    font-family: "GothamBook";
     text-align: center;
  }
  .auth-btns-container > div > a:first-child:hover {
    opacity: 0.8;
  }
  .auth-btns-container > div > a:last-child {
    width: 100%;
    max-width: 200px;
    font-size: 20px;
    line-height: 0px;
    margin: 0px 0px 40px !important;
    color: rgb(38, 50, 56);
    font-family: "GothamBook";
    text-align: center;
    padding: 25px;
    border: 1px solid rgb(38, 50, 56);
    margin-left: 0.75rem;
  }
  .cross {
    width: 100%;
    justify-content: flex-end;
  }
  .box-nav {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .first-section {
    padding-left: 30px;
    justify-content: flex-start;
  }
  .first-section > a > svg {
    margin-top: 7px;
  }
  #nav-bar {
    padding: 0px;
  }
}
</style>
